<template>
  <div class="brace max-md:p-0 max-md:bg-white font-roboto">
    <div class="pt-1 pb-10 md:pb-26 md:rounded-3xl md:bg-white">
      <div class="brace mb-1 md:mb-8 pt-20 lg:pt-24 pb-8">
        <icon-facebook class="w-20 h-20 mx-auto mb-10 md:mb-15" :fontControlled="false" />

        <h2 class="mb-6 text-center text-2xl lg:text-3.5xl font-degular font-bold">
          {{ t("facebook.title") }}
        </h2>

        <p class="max-w-[700px] mx-auto text-sm text-center xl:text-base">
          {{ t("facebook.body") }}
        </p>
      </div>

      <div v-if="width < 860 && swiperReady">
        <Swiper @slideChange="onSlideChange" @swiper="onSwiper">
          <SwiperSlide class="p-4" v-for="(slide, index) in slides" :key="index">
            <div class="h-full p-8 pt-7 shadow-[0_0.25rem_1rem_0_rgba(0,0,0,0.25)] rounded-2xl text-center">
              <img class="!w-[80px] !h-[80px] mx-auto mb-6" :src="slide.image" />
              <p class="mb-4 text-md font-bold">{{ slide.name }}</p>
              <p class="mb-3 text-2xl font-bold text-green font-degular">{{ slide.title }}</p>
              <p>{{ slide.body }}</p>
            </div>
          </SwiperSlide>
        </Swiper>

        <div class="p-6 flex justify-center items-center gap-2">
          <div v-for="(slide, index) in slides" :key="index" class="flex-none">
            <button
              :class="index === currentIndex ? 'bg-green border-green' : 'bg-black border-black'"
              class="rounded-full border border-solid h-2.5 w-2.5 mx-1"
              @click="onSlideClick(index)"
            ></button>
          </div>
        </div>
      </div>
      <div v-else class="flex mb-4 px-[60px]">
        <div v-for="(slide, index) in slides" :key="index" class="w-1/3 text-center">
          <img class="!w-[95px] !h-[95px] mx-auto" :src="slide.image" />

          <div class="mt-4 px-4 pt-2 border-s" :class="{ 'border-s-0': index === 0 }">
            <p class="mb-3 font-bold xl:text-lg">{{ slide.name }}</p>
            <p class="mb-3 font-bold text-green xl:text-2xl font-degular">{{ slide.title }}</p>
            <p>{{ slide.body }}</p>
          </div>
        </div>
      </div>

      <div class="brace mt-5 md:mt-16 text-center">
        <a class="btn btn--outline"
           target="_blank"
           href="https://www.facebook.com/groups/603833893144097/"
           >{{ t("facebook.join") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import IconFacebook from '~/assets/svg/fb_rounded.svg';
import { useWindowSize } from '@vueuse/core';

export default {
  name: 'SectionFacebook',
  components: { IconFacebook },
  setup() {
    const { t } = useI18n({ useScope: 'local' });
    const { width } = useWindowSize();
    const currentIndex = ref(0);
    const swiper = ref(null);
    const swiperReady = ref(false);

    const slides = [
      {
        image: '/images/homepage/jocelyn.png',
        name: t('facebook.users.one.name'),
        title: t('facebook.users.one.title'),
        body: t('facebook.users.one.body'),
      },
      {
        image: '/images/homepage/jimmy.png',
        name: t('facebook.users.two.name'),
        title: t('facebook.users.two.title'),
        body: t('facebook.users.two.body'),
      },
      {
        image: '/images/homepage/nadine.png',
        name: t('facebook.users.three.name'),
        title: t('facebook.users.three.title'),
        body: t('facebook.users.three.body'),
      },
    ];

    const onSwiper = (sw) => {
      swiper.value = sw;
    };

    const onSlideChange = () => {
      currentIndex.value = swiper.value.realIndex;
    };

    const onSlideClick = (index) => {
      swiper.value.slideTo(index);
    };

    onMounted(() => {
      swiperReady.value = true;
    });

    return {
      t,
      width,
      slides,
      currentIndex,
      swiper,
      swiperReady,
      onSwiper,
      onSlideChange,
      onSlideClick,
    };
  },
};
</script>

<style lang="scss" scoped></style>

<i18n lang="json">
{
  "fr": {
    "facebook": {
      "title": "COMMUNAUTÉ FACEBOOK",
      "body": "Ce groupe, c'est le vôtre! Rejoignez une communauté inspirante et encourageante, à qui vous pourrez donner et recevoir du soutien. Partagez vos difficultés, vos trucs pour laisser passer vos envies et surtout, célébrez vos réussites!",
      "join": "Rejoindre le groupe Facebook",
      "users": {
        "one": {
          "image": "/images/homepage/jocelyn.png",
          "name": "Jocelyn",
          "title": "410 jours d'arrêt",
          "body": "« Il y a eu une petite parenthèse en octobre dernier, mais c'est vraiment l'fun de voir ça! »"
        },
        "two": {
          "image": "/images/homepage/jimmy.png",
          "name": "Jimmy",
          "title": "1 200 cigarettes non fumées",
          "body": "« Salut la plus belle gang au monde, plus de 2 mois pour moi! »"
        },
        "three": {
          "image": "/images/homepage/nadine.png",
          "name": "Nadine",
          "title": "500 jours d'arrêt",
          "body": "« Chaque journée est une belle victoire et je suis fière de moi. 🥰 »"
        }
      }
    }
  },
  "en": {
    "facebook": {
      "title": "FACEBOOK COMMUNITY",
      "body": "This is your group! Join an inspiring and supportive community where you can give and receive support. Share your difficulties, your tips for letting go of cravings and, above all, celebrate your successes!",
      "join": "Join the Facebook group",
      "users": {
        "one": {
          "image": "/images/homepage/jocelyn.png",
          "name": "Jocelyn",
          "title": "410 days without smoking",
          "body": "« I had a small relapse last october but it's really fun to witness my progress! »"
        },
        "two": {
          "image": "/images/homepage/jimmy.png",
          "name": "Jimmy",
          "title": "1,200 fewer cigarettes",
          "body": "« Greetings to the most amazing community, I haven't smoked for over 2 months! »"
        },
        "three": {
          "image": "/images/homepage/nadine.png",
          "name": "Nadine",
          "title": "500 days without smoking",
          "body": "« Every day is a beautiful victory and I am proud of myself 🥰 »"
        }
      }
    }
  }
}
</i18n>
